html,
body{
  overflow: hidden;
}

.Authorize {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-color:#FFF;
}
.Landscape{
  display: none;
  width: 100vw;
  height: 100vh;
  background-image: url(../public/bg_landscape.svg);
  background-position: center;
  background-size: cover;
  background-color:#FFF;
  position: fixed;
  top: 0;
  left: 0;
}
.Landscape .Notice{
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100vw;
  text-align: center;
}
.Authorize-text{
  color: #4a4a4a;
  text-align: center;
  font-weight: 300;
  line-height: 1.4em;
  font-size: 1.2em;
}
h1{
  text-align: center;
  margin: 0;
  font-size: 1.3rem;
  color: #059aa5;
  font-weight: 400;
  margin-bottom: 15px;
}
p{
  text-align: center;
}
.Authorize-slide{
  position: absolute;
  width: 80vw;
  min-height: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #dff8f6;
  padding: 60px 20px 40px;
  border-radius: 30px;
  box-sizing: border-box;
}
@media screen and (max-width: 400px) {
  .Authorize-slide h1{
    font-size:1.1em; 
  }
  .Authorize-slide p{
    font-size:1em; 
  }
}
.Authorize-slide .content{
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}
.Authorize-slide:before{
  position: absolute;
  content:'';
  display: block;
  width: 90px;
  height: 90px;
  background-image:url('../public/step1.svg');
  background-size: contain;
  top: 0;
  left: 50%;
  transform:translate(-50%, -50%);
}
.Authorize-slide.step-1:before{
  background-image:url('../public/step2.svg');
}
.Authorize-slide.step-2:before{
  background-image:url('../public/step3.svg');
}
.flex_row{
  position: fixed;
  bottom: 40px;
  display: block;
  width: 100%;
  text-align: center;
}
.flex_row span{
  font-size: 50px;
  letter-spacing: 7px;
  color: #abb4b3;
}
.flex_row span.active{
  color: #059aa5;
}
.navigate{
  position: fixed;
  bottom: 30px;
  width: 100%;
  text-align: right;
}
.navigate button{
  border: 0;
  background-color: #059aa5;
  color: #fff;
  padding: 15px 30px 15px 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 1em;
}
.navigate button:after{
  content:'';
  width: 20px;
  height: 15px;
  background-image: url(../public/arrow-right.svg);
  background-position: center center;
  background-size: contain;
  position:absolute;
  right: 5px;
  top:50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}
.Authorize-error a {
  color: #fbae47;
}

.App-Loader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Authorize-products{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-top: 20px;
}
.Authorize-product{
  text-align: center;
}
.Authorize-products img{
  width: 30vw;
}
.Authorize-products h2{ 
  font-size: 1.3em;
  margin-bottom: 0;
  font-weight: 400;
  color: #059aa5;
  text-decoration: none;
}
@media screen and (max-width: 400px) {
  .Authorize-products img{
    width: 25vw;
  }
  .Authorize-products h2{ 
    font-size: 1em;
  }
}
.Authorize-products a{
  text-decoration: none;
}
.Authorize-choose .Authorize-item{
  padding: 15px;
  background-color: rgba(255,255,255,0.3);
  border-radius: 20px;
}
.Authorize-choose .Authorize-item{
  max-width: 100%;
}
@media (orientation: landscape) {
  body{
    background-color: #00bfb3;
  }
  .Authorize {
    display: none;
  }
  .Landscape{
    display:block;
  }
}