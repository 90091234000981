html{
    overflow: hidden;
}
body{
    margin: 0;
    padding:0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #000;
}
button.Take-photo{
    display: block;
    width: 20vw;
    height: 20vw;
    position: absolute;
    top: calc(100vw * 1.7);
    left: 50%;
    transform: translate(-50%, -100%);
    border-radius: 50%;
    background-color: rgba(255,255,255,0.6);
    border: 5px solid #FFF;
}
button.Take-photo span{
    display:none;
}