.Error404{
    width: 100vw;
    height: 100vh;
    background-position: center center;
    background-size: cover;
    background-color:#04a898;
    background-image: url(../public/fond_bleu.svg);
}
.Error404 .container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.Error404 .container h1{
    color: #262829;
    font-size: 3em;
}