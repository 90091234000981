.Splash{
    width: 100vw;
    height: 100vh;
    background-position: center center;
    background-size: cover;
    background-color:#04a898;
    background-image: url(../public/fond_bleu.svg);
}
.Splash-logo{
    position: fixed;
    width: 100vw;
    height: 100px;
    background-color: #FFF;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.Splash-logo img{
    max-height: 80%;
    height: 40%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: SplashAnim 2s ease-in 0s 1 normal forwards;
}
@keyframes SplashAnim {
	0% {
		opacity: 0;
        height: 40%;
	}
	100% {
		opacity: 1;
        height: 80%;
	}
}