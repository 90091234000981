@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');
html{
  overflow: hidden;
}
body {
  overflow: hidden;
  font-family: Lato, sans-serif;
  margin: 0;
}

.App-Loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.App-Loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #04a898;
  border-color: #04a898 transparent #04a898 transparent;
  animation: App-Loader 1.2s linear infinite;
}
@keyframes App-Loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
