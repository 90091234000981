html{
    overflow: hidden;
}
body{
    margin: 0;
    padding:0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #000;
}
.Scan-mask{
    width: 100vw;
    height: 110vw;
    object-fit: cover;
    object-position: 50% 0;
    position: absolute;
    top:0;
    left:0;
}
#interactive{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vw;
    overflow: hidden;
}
#interactive > video {
    width: 100%;
    height: auto;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#interactive> canvas.drawing, canvas.drawingBuffer {
    position: absolute;
    left: 0;
    top: 0;
}
.Scan-Shock-mire{
    position: absolute;
    display: block;
    width: 20px;
    height: 15px;
    left: 50%;
    top: 54vw;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border:2px solid #e80713;
    animation: Scan-mire-anim 2s linear infinite;
}
.Scan-Roll-mire{
    position: absolute;
    display: block;
    width: 44px;
    height: 44px;
    left: 50%;
    top: 60vw;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border:2px solid #e80713;
    animation: Scan-mire-anim 2s linear infinite;
}
.Scan-flashToggle{
    position:absolute;
    top: 90vw;
    right: 3%;
    width: 10vw;
    height: 10vw;
    background-color: transparent;
    background-image:url(../public/lampoff.svg);
    background-position:center;
    background-size: 60% 60%;
    background-repeat: no-repeat;
    border-radius:50%;
    border:3px solid #FFF;
}
.Scan-flashToggle.active{
    background-image:url(../public/lampon.svg);
    border-color: #059aa5;
}
.Scan-flashToggle span{
    display: none;
}

.Scan-results,
.Scan-Comment,
.Scan-Photos,
.Scan-Loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-color:#FFF;
    background-image: url(../public/fond_blanc.svg);
}
.Scan-results .container,
.Scan-Comment .container,
.Scan-Photos .container,
.Scan-Loading .container{
    position: absolute;
    width: 90vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #e2f2f7;
    padding: 60px 20px 40px;
    border-radius: 30px;
    box-sizing: border-box;
}
.Scan-Loading .container{
    min-height: 60vw;
}
.Scan-results .container:before,
.Scan-Comment .container:before,
.Scan-Photos .container:before{
    position: absolute;
    content:'';
    display: block;
    width: 90px;
    height: 90px;
    background-image:url('../public/sensorOk.svg');
    background-size: contain;
    top: 0;
    left: 50%;
    transform:translate(-50%, -50%);
}
.Scan-results .container.ko:before{
    background-image:url('../public/sensorKo.svg');
}
.Scan-Comment .container:before{
    background-image:url('../public/comment.svg');
}
.Scan-Photos .container:before{
    background-image:url('../public/photo.svg');
}
.Scan-results .container h1,
.Scan-consignes .container h1,
.Scan-Photos .container h1{
    text-align: center;
    margin: 0;
    font-size: 1.3rem;
    color: #059aa5;
    font-weight: 400;
    margin-bottom: 15px;
}
.Scan-results .container p,
.Scan-results .container li,
.Scan-consignes .container p,
.Scan-consignes .container li,
.Scan-Photos .container p,
.Scan-Photos .container li,
.Scan-Comment .container p,
.Scan-Comment .container li
{
    color: #4a4a4a;
    text-align: center;
    font-weight: 300;
    line-height: 1.3em;
    font-size: 1em;
}
.Scan-consignes .container p,
.Scan-consignes .container li{
    text-align: center;
}

.Scan-results .container ul{
    margin:0;
    padding:0;
    list-style: none;
}
.Scan-results .container ul li{
    margin: 5px 0;
}
.Scan-results .container li span{
    font-weight: 400;
}

.Scan-results .actions,
.Scan-consignes .actions,
.Scan-Comment .actions,
.Scan-Photos .actions {
    width: 90%;
    margin: 30px auto 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    object-position: center;
}
.Scan-consignes .actions {
    margin:5px auto 0;
}
.Scan-results .actions.solo,
.Scan-consignes .actions.solo,
.Scan-Comment .actions.solo,
.Scan-results .actions.solo2,
.Scan-consignes .actions.solo2,
.Scan-Comment .actions.solo2{
    grid-template-columns: 1fr;
}
.Scan-results .actions.solo2 button,
.Scan-consignes .actions.solo2 button,
.Scan-Comment .actions.solo2 button{
    width:50%;
    margin: 0 auto;
}
.Scan-results .actions button,
.Scan-consignes .actions button,
.Scan-Comment .actions button,
.Scan-Photos .actions button{
    width: 100%;
    height: 40px;
    border-radius: 0;
    background: rgba(232, 7, 19, 1);
    color: #FFF;
    font-size: 1.1em;
    line-height: 30px;
    border:0;
}
.Scan-results .actions button.continue,
.Scan-consignes .actions button.continue,
.Scan-Comment .actions button.continue,
.Scan-Photos .actions button.continue{
    background: #059aa5;
}
.Scan-results .actions button.back,
.Scan-consignes .actions button.back,
.Scan-Comment .actions button.back,
.Scan-Photos .actions button.back{
    background: #616161;
}
.Scan-results .actions button.hidden,
.Scan-consignes .actions button.hidden,
.Scan-Comment .actions button.hidden,
.Scan-Photos .actions button.hidden{
    display: none;
}
.Scan-results .actions.row{
    grid-template-columns: 1fr;
    width: 100%;
}
.Scan-results .actions.row button{
    background: none;
    color: #4a4a4a;
    text-align: left;
    font-size: 0.9em;
}
.Scan-results .actions.row button.continue{
    height: auto;
    line-height: 20px;
    padding: 20px 10px;
    text-align: center;
    color: #FFF;
    background-color: #059aa5;
    font-size: 1.2em;
}
.Scan-consignes {
    display: block;
    width: 100vw;
    height: calc(100% - 110vw);
    background: #FFF;
    position: absolute;
    bottom: 0;
    left: 0;
    background-position: center bottom;
    background-size: 150%;
    background-color:#FFF;
    background-image: url(../public/fond_bleu.svg);
    transition: height 0.3s linear;
    overflow: hidden;
}
main.manual .Scan-consignes {
    height: calc(50%);
    transition: height 0.3s linear;
}
.Scan-consignes .container{
    position: absolute;
    width: 90vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #e2f2f7;
    padding: 15px;
    border-radius: 30px;
    box-sizing: border-box;
}
.Scan-consignes .container ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
.Scan-consignes .container ul li{
    margin: 10px 0;
    position: relative;
}
.Scan-consignes .container ul li:after{
    display: block;
    content:'';
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    height: 3px;
    background-color: #059aa5;
}
.Scan-consignes .container ul li:nth-child(1){
    animation: Scan-consigne1 8s linear forwards;
}
.Scan-consignes .container ul li:nth-child(2){
    animation: Scan-consigne2 8s linear forwards;
}
.Scan-consignes .container ul li:nth-child(3){
    animation: Scan-consigne3 8s linear forwards;
}
.Scan-consignes .container ul li:last-child:after{
    display:none;
}
.Scan-consignes label{
    width: 100%;
    padding: 0;
    margin-top:10px;
    display: block;
    color: #4a4a4a;
    text-align: left;
    font-weight: 300;
    line-height: 1.2em;
    font-size: 1em;
}
.Scan-consignes label input,
.Scan-consignes label select{
    color: #4a4a4a;
    text-align: left;
    font-weight: 300;
    line-height: 1.4em;
    font-size: 1em;
    background-color: #FFF;
    border-color: #059aa5;
    width: 100%;
    border-radius: 0;
    border-width: 1px;
    box-sizing: border-box;
    margin-top:5px;
}
.Scan-Photos .Photos-add {
    margin: 20px auto;
    min-width: 20vw;
    display: block;
    padding: 10px 10px 10px 50px;
    background-image: url(../public/photo.svg);
    background-position: 10px 50%;
    background-size: 30px auto;
    background-repeat: no-repeat;
    border:0;
    background-color: transparent;
    border:1px solid #059aa5;
    color:#059aa5;
    font-weight: 500;
}
.Scan-Photos .Photos-list{
    margin: 20px 0;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.Scan-Photos .Photos-list .Photo{
    position:relative;
}
.Scan-Photos .Photos-list .Photo .Photo-Remove{
    display:block;
    width: 100%;
    height: 30px;
    background-color: rgba(232, 7, 19, 1);
    color: #FFF;
    border: 0;
    margin-top: -6px;
}
button.redStatus,
button.greenStatus{
    width: 100%;
    padding: 15px 10px 15px 50px;
    border: 1px solid rgba(232, 7, 19, 1);
    background: none;
    border-radius: 15px;
    color: rgba(232, 7, 19, 1);
    margin: 10px 0;
    position:relative;
}
button.redStatus:before,
button.greenStatus:before{
    content:'';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background-image: url('../public/sensorKo.svg');
    background-repeat: no-repeat;
    background-position: 0 center;
}
button.greenStatus{
    border-color: #059aa5;
    color: #059aa5;
}
button.greenStatus:before{
    background-image: url('../public/sensorOk.svg');
}
button.redStatus.active,
button.greenStatus.active{
    color:#FFF;
    background-color: rgba(232, 7, 19, 1);
}
button.greenStatus.active{
    background-color: #059aa5;
}
button.redStatus.active:before,
button.greenStatus.active:before{
    filter: grayscale(1) brightness(200);
}
.barcode input{
    text-transform: uppercase;
}
.mailfield,
.Scan-Comment textarea{
    margin-top:10px;
}
@media screen and (max-width: 400px) {
    .Scan-results .container h1,
    .Scan-consignes .container h1{
      font-size:1.1em; 
    }
    .Scan-results .container p,
    .Scan-results .container li,
    .Scan-consignes .container p,
    .Scan-consignes .container li{
      font-size:1em; 
    }
}
@keyframes Scan-mire-anim {
    0% {
        background-color: rgba(232, 7, 19, 0);
    }
    10% {
        background-color: rgba(232, 7, 19, 0.5);
    }
    20% {
        background-color: rgba(232, 7, 19, 0);
    }
    30% {
        background-color: rgba(232, 7, 19, 0.5);
    }
    40% {
        background-color: rgba(232, 7, 19, 0);
    }
    50% {
        background-color: rgba(232, 7, 19, 0.5);
    }
    60% {
        background-color: rgba(232, 7, 19, 0);
    }
}
@keyframes Scan-consigne1 {
    40% {
        color: inherit;
    }
    45% {
        color: rgba(232, 7, 19, 1);
    }
    50% {
        color: rgba(232, 7, 19, 1);
    }
    55% {
        color: rgba(232, 7, 19, 1);
    }
    60% {
        color:  inherit;
    }
}
@keyframes Scan-consigne2 {
    60% {
        color: inherit;
    }
    65% {
        color: rgba(232, 7, 19, 1);
    }
    70% {
        color: rgba(232, 7, 19, 1);
    }
    75% {
        color: rgba(232, 7, 19, 1);
    }
    80% {
        color:  inherit;
    }
}
@keyframes Scan-consigne3 {
    80% {
        color: inherit;
    }
    85% {
        color: rgba(232, 7, 19, 1);
    }
    90% {
        color: rgba(232, 7, 19, 1);
    }
    95% {
        color: rgba(232, 7, 19, 1);
    }
    100% {
        color:  inherit;
    }
}